<template>
  <div class="document">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <div class="sign_pic">
        <div class="form-item ">
          <span>Signature</span>
          <el-form-item
            v-if="sign_pic.url"
            ref="sign_pic"
            prop="sign_pic">
            <file-container-card
              :edit="true"
              :can-del="false"
              :fileData="sign_pic"
            />
          </el-form-item>
          <div
            v-else
          >
            -
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapState,mapActions} from 'vuex'
import {checkPolicyNumber,checkCovernote} from '@api/policy'
export default {
  name: 'signForm',
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    classType:{
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 0
    },
    clientType:{
      type: Number,
      default: 1
    }
  },
  data(){
    return{
      oldFormDataLock:-1,
      formData:{
      }
    }
  },
  computed:{
    ...mapState('quotation', ['quoteInformationForm']),
    sign_pic(){
      let {signature={}} = this.quoteInformationForm
      let sign_data = signature||{}
      return sign_data
    },
  },
  watch:{
    form: {
      deep: true,
      immediate: true,
      handler(val) {

      }
    },
  },
  methods:{
    // file-container 組件刪除文件
    handlerDeleteFile(otherData) {
      const { idKey, nameKey, urlKey,name } = otherData
      this.$set(this.formData, idKey, '')
      this.$set(this.formData, urlKey, '')
      this.$set(this.formData, nameKey, '')
      this.$set(this.formData, name, {})
      console.log(this.formData[name])
      console.log(this.formData[urlKey])
    },
    handlerUploadSuccess({ data, result, otherData }) {
      const {  idKey,  urlKey,name } = otherData
      const { file_id,  url,path } = data
      this.$set(this.formData, idKey, file_id)
      this.$set(this.formData, urlKey, url)
      this.$set(this.formData, name, data)
    },
    handlerDelete({otherData}){
      let {index} = otherData||{}
      this.formData.other_documents.splice(index,1)
      this.$refs.upload.clearFiles()
    },
    handlerDocumentSuccess({ data, result, otherData }){
      const { file_id,path,url } = data
      this.formData.other_documents.push({file_id,client_name:path,url:url})

    },
    openFile(url){
      if(url){
        window.open(url,'_blank')
      }
    },
  }
}
</script>

<style scoped lang="scss">
.document{
  background: #fff;
  border-radius: 8px;
  padding: 20px;
}
.sign-pic{
  width: 335px;
  background: #fff;
  height: 200px;
  border-radius: 8px;
}
</style>
